import styled from 'styled-components'

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`

export const Spinner = styled.div`
  width: 36px;
  height: 36px;
  border: 3px solid #fff;
  border-bottom-color: rgba(108, 117, 125, 1);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
