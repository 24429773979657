import dayjs from 'dayjs'
import * as Styled from './FiltersCalendarDay.styled'

export function FiltersCalendarDay({
  date,
  isCurrentDate,
  onClick,
}: {
  date: string
  isCurrentDate: boolean
  onClick: (date: string) => void
}) {
  const handleClick = () => {
    onClick(date)
  }

  return (
    <Styled.DayContainer $isCurrent={isCurrentDate} onClick={handleClick}>
      <Styled.WeekDay>{dayjs(date).format('ddd')}</Styled.WeekDay>
      <Styled.DayTitle>{dayjs(date).format('MMM D')}</Styled.DayTitle>
    </Styled.DayContainer>
  )
}
