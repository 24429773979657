import Select, { Props as SelectProps } from 'react-select'
import styles from './UISelect.module.css'

export function UISelect<T, Multi extends boolean>({ ...props }: SelectProps<T, Multi>) {
  return (
    <Select
      components={{
        IndicatorSeparator: () => null,
      }}
      unstyled
      classNamePrefix="widgetSelect"
      className={styles.widgetSelect}
      {...props}
    />
  )
}
