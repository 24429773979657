import styled from 'styled-components'

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 5px;
`

export const Title = styled.div`
  white-space: pre-wrap;
  font-size: 24px;
  font-weight: 600;
  color: rgb(0, 0, 0);
  text-align: center;
  text-shadow: rgb(153, 150, 150) 1px 1px 0;
  line-height: 1;
`

export const LabelContainer = styled.div`
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
`

export const Label = styled.div`
  white-space: pre-wrap;
  font-size: 17px;
  font-weight: 600;
  color: rgb(0, 0, 0);
  text-align: center;
  line-height: 1;
`
