import { ReactNode } from 'react'
import { InitData } from './components/InitData/InitData'
import { InitWidgetType } from './components/InitWidgetType/InitWidgetType'

export function InitProvider({ children }: { children: ReactNode }) {
  return (
    <InitWidgetType>
      <InitData>{children}</InitData>
    </InitWidgetType>
  )
}
