import styled from 'styled-components'
import { ReactComponent as IconLeftArrow } from 'assets/img/IconLeftArrow.svg'

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

export const Days = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-grow: 1;
`

export const ArrowLeft = styled(IconLeftArrow)<{ $isHidden: boolean }>`
  cursor: pointer;
  height: 15px;
  fill: rgba(107, 179, 227, 1);
  visibility: ${({ $isHidden }) => ($isHidden ? 'hidden' : 'visible')};
`

export const ArrowRight = styled(IconLeftArrow)<{ $isHidden: boolean }>`
  cursor: pointer;
  height: 15px;
  fill: rgba(107, 179, 227, 1);
  transform: rotate(180deg);
  visibility: ${({ $isHidden }) => ($isHidden ? 'hidden' : 'visible')};
`
