import styled from 'styled-components'

export const GamesList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 1fr;
  column-gap: 6px;
  row-gap: 6px;
  margin-top: 6px;
`

export const ButtonContainer = styled.a`
  background-color: rgb(237, 237, 237);
  padding: 10px;
  display: flex;
  text-decoration: none;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`

export const Button = styled.button`
  background-color: rgba(108, 117, 125, 1);
  font-size: 14px;
  color: #fff;
  text-align: center;
  border-radius: 10px;
  transition: opacity 200ms ease 0s;
  width: 100%;
  padding: 0;
  border: 0;
  height: 35px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`

export const NoGamesTitle = styled.div`
  width: 100%;
  text-align: center;
`

export const EmptyContainer = styled.div`
  margin-top: 6px;
`
