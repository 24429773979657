import { ReactNode } from 'react'
import { getQueryParams } from 'utils'
import * as Styled from './LayoutContainer.styled'

export function LayoutContainer({ children }: { children: ReactNode }) {
  const { w, h } = getQueryParams()
  const width = w ? `${w}px` : '100%'
  const height = h ? `${h}px` : '100%'

  return (
    <Styled.Container $width={width} $height={height} className="layout">
      {children}
    </Styled.Container>
  )
}
