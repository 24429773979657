import styled from 'styled-components'

export const Row = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr max-content max-content max-content;
  gap: 3px;
  align-items: center;
`

export const Avatar = styled.img`
  width: 15px;
  height: auto;
`

export const DefaultAvatar = styled.img`
  width: 15px;
  height: auto;
  margin-top: -4px;
`

export const SchoolName = styled.div<{ $isWon: boolean }>`
  font-size: 12px;
  font-weight: ${({ $isWon }) => ($isWon ? 600 : 400)};
  color: rgba(52, 58, 64, 1);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Standings = styled.div`
  font-size: 12px;
  color: rgba(108, 117, 125, 1);
`

export const Score = styled.div<{ $isWon: boolean }>`
  font-size: 14px;
  font-weight: 600;
  color: ${({ $isWon }) => ($isWon ? '#000' : 'rgba(108, 117, 125, 1)')};
  min-width: 22px;
  text-align: right;
`

export const WinMark = styled.div`
  width: 5px;

  svg {
    fill: rgba(237, 28, 36, 1);
    height: 10px;
  }
`
