import { useSelector } from 'react-redux'
import { getCalendar, getSelectedDate } from '../../state/Filters.selectors'
import { FiltersCalendar } from '../FiltersCalendar/FiltersCalendar'

export function FiltersCalendarContainer() {
  const selectedDate = useSelector(getSelectedDate)
  const calendar = useSelector(getCalendar)

  if (calendar.length === 0 || !selectedDate) {
    return null
  }

  return <FiltersCalendar />
}
