import { rtkApi } from 'app/store/AppStore.rtk'
import {
  CalendarRequest,
  CalendarResponse,
  DistrictListResponse,
  School,
  SportListResponse,
  State,
} from 'types'

export const initApi = rtkApi
  .enhanceEndpoints({ addTagTypes: ['state', 'districts', 'sports', 'school', 'calendar'] })
  .injectEndpoints({
    endpoints: (build) => ({
      getStateById: build.query<State, string>({
        query: (stateId) => `/states/${stateId}`,
        providesTags: ['state'],
      }),

      getSchoolById: build.query<School, string>({
        query: (schoolId) => `/school?id=${schoolId}`,
        providesTags: ['school'],
      }),

      getDistricts: build.query<DistrictListResponse, string>({
        query: (stateId) => {
          return {
            url: `/districts`,
            params: {
              stateId,
              isActive: true,
              page: 1,
              perPage: 500,
            },
          }
        },
        providesTags: ['districts'],
      }),

      getSports: build.query<SportListResponse, string>({
        query: (stateId) => {
          return {
            url: `/sports`,
            params: {
              stateId,
              page: 1,
              perPage: 500,
            },
          }
        },
        providesTags: ['sports'],
      }),

      getCalendar: build.query<CalendarResponse, CalendarRequest>({
        query: (request: CalendarRequest) => {
          const { state, district, school } = request
          return {
            url: `/games/getCalendar`,
            params: {
              stateId: state,
              districtId: district,
              schoolId: school,
            },
          }
        },
        providesTags: ['calendar'],
      }),
    }),
  })

export const {
  useGetStateByIdQuery,
  useGetDistrictsQuery,
  useGetSportsQuery,
  useGetSchoolByIdQuery,
  useGetCalendarQuery,
} = initApi
