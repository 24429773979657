import {
  setFilterDate,
  setFilterDistrict,
  setFilterSchool,
  setFilterState,
} from 'app/imports/App.actions'
import dayjs from 'dayjs'
import { UISpinner } from 'features/UI'
import { useAppDispatch } from 'hooks'
import { ReactNode, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { WidgetTypes } from 'types'
import { findClosestDate, getQueryParams } from 'utils'
import { setFilterCalendar } from '../../../Filters/state/Filters.reducer'
import {
  useGetCalendarQuery,
  useGetDistrictsQuery,
  useGetSchoolByIdQuery,
  useGetSportsQuery,
  useGetStateByIdQuery,
} from '../../state/Init.rtk'
import { selectWidgetType } from '../../state/Init.selectors'

export function InitData({ children }: { children: ReactNode }) {
  const dispatch = useAppDispatch()
  const widgetType = useSelector(selectWidgetType)
  const isStateWidget = widgetType === WidgetTypes.State
  const isCountyWidget = widgetType === WidgetTypes.District
  const isSchoolWidget = widgetType === WidgetTypes.School

  const { state, district, school } = getQueryParams()
  const { data: stateData, isLoading: isStateLoading } = useGetStateByIdQuery(state, {
    skip: !state,
  })

  const { data: districtData, isLoading: isCountiesLoading } = useGetDistrictsQuery(state, {
    skip: !district,
  })

  const { isLoading: isSportsLoading } = useGetSportsQuery(state, {
    skip: !isStateWidget && !isCountyWidget,
  })

  const { data: schoolData, isLoading: isSchoolLoading } = useGetSchoolByIdQuery(school, {
    skip: !school && !isSchoolWidget,
  })

  const { data: calendarData, isLoading: isCalendarLoading } = useGetCalendarQuery(
    { state, district, school },
    {
      skip: !school && !isSchoolWidget,
    },
  )

  const isLoading = useMemo(() => {
    if (isStateWidget || isCountyWidget) {
      return isStateLoading && isCountiesLoading && isSportsLoading
    }

    if (isSchoolWidget) {
      return isStateLoading && isCountiesLoading && isSchoolLoading && isCalendarLoading
    }

    return false
  }, [
    isCalendarLoading,
    isCountiesLoading,
    isCountyWidget,
    isSchoolLoading,
    isSchoolWidget,
    isSportsLoading,
    isStateLoading,
    isStateWidget,
  ])

  useEffect(() => {
    if (stateData) {
      dispatch(setFilterState(stateData))
    }

    if (districtData) {
      // find district by id from query param
      const districtValue = districtData.items.find((districtItem) => districtItem.id === district)
      dispatch(setFilterDistrict(districtValue || null))
    }

    if (schoolData) {
      dispatch(setFilterSchool(schoolData))
    }

    if (!isSchoolWidget) {
      dispatch(setFilterDate(dayjs().format('YYYY-MM-DD')))
    }

    if (calendarData) {
      const closestDate = findClosestDate(calendarData.calendar)
      dispatch(setFilterCalendar(calendarData.calendar.slice().reverse()))
      dispatch(setFilterDate(closestDate))
    }
  }, [calendarData, dispatch, district, districtData, isSchoolWidget, schoolData, stateData])

  if (isLoading) {
    return <UISpinner />
  }

  return <>{children}</>
}
