import { useAppDispatch, useEffectOnce } from 'hooks'
import { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { WidgetTypes } from 'types'
import { getQueryParams } from 'utils'
import { setWidgetType } from '../../state/Init.reducer'
import { selectWidgetType } from '../../state/Init.selectors'
import * as Styled from './InitWidgetType.styled'

export function InitWidgetType({ children }: { children: ReactNode }) {
  const dispatch = useAppDispatch()
  const widgetType = useSelector(selectWidgetType)

  const { state, district, school } = getQueryParams()

  useEffectOnce(() => {
    if (widgetType !== null) return

    let type = null
    if (state && district && school) {
      type = WidgetTypes.School
    } else if (state && district) {
      type = WidgetTypes.District
    } else if (state) {
      type = WidgetTypes.State
    }
    dispatch(setWidgetType(type))
  })

  if (!widgetType) {
    return <Styled.ErrorTitle>Widget is not configured</Styled.ErrorTitle>
  }

  return <>{children}</>
}
