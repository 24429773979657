import styled from 'styled-components'

export const Container = styled.div<{ $width: string; $height: string }>`
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  background-color: rgb(237, 237, 237);
  display: flex;
  flex-direction: column;
  position: relative;
`
