import { combineReducers, configureStore, Reducer } from '@reduxjs/toolkit'
import { appFeatures } from '../App.features'
import { rtkApi } from './AppStore.rtk'

function composeReducers() {
  return appFeatures.reduce(
    (memo, feature) => {
      if (feature.store && feature.store.reducer) {
        return {
          ...memo,
          [feature.title!]: feature.store.reducer,
        }
      }

      return memo
    },
    {} as Record<string, Reducer>,
  )
}

export const AppStore = configureStore({
  reducer: {
    app: combineReducers(composeReducers()),
    [rtkApi.reducerPath]: rtkApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(rtkApi.middleware),
})
