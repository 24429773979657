import { createSelector } from '@reduxjs/toolkit'
import { Nullish, RootState, WidgetTypes } from 'types'
import { FEATURE_NAME, IState } from '../Init.model'

export const selectInitSlice = (state: RootState): IState => state.app[FEATURE_NAME]

export const selectWidgetType = createSelector(
  selectInitSlice,
  (slice): Nullish<WidgetTypes> => slice.widgetType,
)
