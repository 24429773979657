import { useAppDispatch } from 'hooks'
import { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { CALENDAR_PAGE_SIZE } from '../../Filters.model'
import { setFilterDate } from '../../state/Filters.reducer'
import { getCalendar, getSelectedDate } from '../../state/Filters.selectors'
import { FiltersCalendarDay } from '../FiltersCalendarDay/FiltersCalendarDay'
import * as Styled from './FiltersCalendar.styled'

export function FiltersCalendar() {
  const dispatch = useAppDispatch()
  const selectedDate = useSelector(getSelectedDate)
  const calendar = useSelector(getCalendar)

  const dateIndex = useMemo(() => {
    return calendar.findIndex((date) => date === selectedDate)
  }, [calendar, selectedDate])

  const [startIndex, setStartIndex] = useState(() => {
    const i = dateIndex - (CALENDAR_PAGE_SIZE - 1)
    return i < 0 ? 0 : i
  })

  const [endIndex, setEndIndex] = useState(dateIndex)

  const displayedDays = useMemo(() => {
    return calendar.slice(startIndex, endIndex + 1)
  }, [calendar, endIndex, startIndex])

  const handleChangeDate = useCallback(
    (date: string) => {
      dispatch(setFilterDate(date))
    },
    [dispatch],
  )

  const handleGoLeft = () => {
    let newStartIndex = startIndex - CALENDAR_PAGE_SIZE
    newStartIndex = newStartIndex < 0 ? 0 : newStartIndex
    const diff = startIndex - newStartIndex

    const newEndIndex = endIndex - diff
    setStartIndex(newStartIndex)
    setEndIndex(newEndIndex)

    const date = calendar[newEndIndex]
    dispatch(setFilterDate(date))
  }

  const handleGoRight = () => {
    let newEndIndex = endIndex + CALENDAR_PAGE_SIZE
    newEndIndex = newEndIndex > calendar.length - 1 ? calendar.length - 1 : newEndIndex
    const diff = newEndIndex - endIndex

    const newStartIndex = startIndex + diff

    setStartIndex(newStartIndex)
    setEndIndex(newEndIndex)

    const date = calendar[newEndIndex]
    dispatch(setFilterDate(date))
  }

  return (
    <Styled.Container>
      <Styled.ArrowLeft $isHidden={startIndex === 0} onClick={handleGoLeft} />

      <Styled.Days>
        {displayedDays.map((date) => {
          return (
            <FiltersCalendarDay
              key={date}
              date={date}
              isCurrentDate={date === selectedDate}
              onClick={handleChangeDate}
            />
          )
        })}
      </Styled.Days>

      <Styled.ArrowRight $isHidden={endIndex === calendar.length - 1} onClick={handleGoRight} />
    </Styled.Container>
  )
}
