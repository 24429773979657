import { IFeature } from 'types'
import { FEATURE_NAME } from './Init.model'
import { InitProvider } from './Init.provider'
import { initSlice } from './state/Init.reducer'

export const InitFeature: IFeature = {
  title: FEATURE_NAME,

  provider: InitProvider,

  store: {
    reducer: initSlice.reducer,
  },
}
