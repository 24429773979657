import dayjs from 'dayjs'

export function findClosestDate(dates: string[]): string {
  const today = dayjs()
  let closestDate = dates[0]
  let minDiff = Math.abs(today.diff(dayjs(dates[0]), 'day'))

  dates.forEach((date) => {
    const diff = Math.abs(today.diff(dayjs(date), 'day'))
    if (diff < minDiff) {
      minDiff = diff
      closestDate = date
    }
  })

  return closestDate
}
