import styled from 'styled-components'

export const Card = styled.a`
  background: #fff;
  border-radius: 3px;
  padding: 5px;
  min-height: 50px;
  height: 100%;
  overflow: hidden;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background: rgba(108, 117, 125, 0.5);
  }
`

export const Date = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: #000;
  line-height: 1.4;
  padding: 0 5px;
`

export const SportRow = styled.div`
  font-size: 12px;
  color: #000;
  text-align: center;
  line-height: 1.4;
  padding: 0 5px;
`
