import { getSelectedSchool } from 'app/imports/App.selectors'
import { useSelector } from 'react-redux'

export function LayoutHeaderSchool() {
  const school = useSelector(getSelectedSchool)

  if (!school) {
    return null
  }

  return <>{school.shortName} Scoreboard</>
}
