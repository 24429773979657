import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { WidgetTypes } from 'types'
import { FEATURE_NAME, IState } from '../Init.model'

const initialState: IState = {
  widgetType: null,
}

export const initSlice = createSlice({
  name: FEATURE_NAME,
  initialState,
  reducers: {
    setWidgetType(state, action: PayloadAction<WidgetTypes | null>) {
      state.widgetType = action.payload
    },
  },
})

export const { setWidgetType } = initSlice.actions
