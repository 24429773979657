import styled from 'styled-components'

export const DayContainer = styled.div<{ $isCurrent: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  color: ${({ $isCurrent }) => ($isCurrent ? '#000' : 'rgb(108, 117, 125)')};
`

export const WeekDay = styled.div`
  font-size: 12px;
  text-align: center;
`

export const DayTitle = styled.div`
  font-size: 10px;
  text-align: center;
`
