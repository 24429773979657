import { getQueryParams } from 'utils'
import { useGetStateByIdQuery } from 'app/imports/App.hooks'

export function LayoutHeaderState() {
  const { state } = getQueryParams()
  const { data } = useGetStateByIdQuery(state)

  if (!data) {
    return null
  }

  return <>{data.name} Scoreboard</>
}
