import { getSelectedDistrict } from 'app/imports/App.selectors'
import { useSelector } from 'react-redux'

export function LayoutHeaderDistrict() {
  const district = useSelector(getSelectedDistrict)

  if (!district) {
    return null
  }

  return <>{district.name} Scoreboard</>
}
