import { IFeature } from 'types'
import { FEATURE_NAME } from './Filters.model'
import { filtersSlice } from './state/Filters.reducer'

export const FiltersFeature: IFeature = {
  title: FEATURE_NAME,

  store: {
    reducer: filtersSlice.reducer,
  },
}
