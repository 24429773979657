import styled from 'styled-components'

export const Content = styled.div`
  padding: 3px;
  overflow: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
`
