import 'assets/fonts/fonts.css'
import 'styles/reset.css'
import { showVersion } from 'features/Env'
import { AppProviders } from './App.providers'
import { Filters, GamesList, LayoutContent, LayoutHeader } from './imports/App.components'

showVersion()

export function App() {
  return (
    <AppProviders>
      <LayoutHeader />
      <LayoutContent>
        <Filters />
        <GamesList />
      </LayoutContent>
    </AppProviders>
  )
}
