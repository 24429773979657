import { useGetSportsQuery } from 'app/imports/App.hooks'
import { UISelect } from 'features/UI'
import { useAppDispatch } from 'hooks'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { OnChangeValue } from 'react-select'
import { Sport } from 'types'
import { getQueryParams } from 'utils'
import { setFilterSport } from '../../state/Filters.reducer'
import { getSelectedSport } from '../../state/Filters.selectors'

export function FiltersByDistrict() {
  const dispatch = useAppDispatch()
  const { state } = getQueryParams()

  const { data: sports } = useGetSportsQuery(state)

  const selectedSport = useSelector(getSelectedSport)

  const sportItems = useMemo(() => {
    if (!sports) {
      return []
    }

    return sports.items.filter((sport) => !sport.isParent)
  }, [sports])

  const handleSportChange = (sport: OnChangeValue<Sport, false>) => {
    dispatch(setFilterSport(sport))
  }

  if (!sports) {
    return null
  }

  return (
    <div style={{ width: '100%' }}>
      <UISelect<Sport, false>
        options={sportItems}
        value={selectedSport}
        onChange={handleSportChange}
        isMulti={false}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        placeholder="SPORT"
      />
    </div>
  )
}
