import { Nullish } from 'types'

export const FEATURE_NAME = 'games'

export interface SchoolStandings {
  won: number
  lose: number
}

export interface GamesByStateRequest {
  stateId?: string
  districtId?: string
  sportId?: string
  date?: string
}

export interface GamesBySchoolRequest {
  stateId?: string
  districtId?: string
  schoolId?: string
  date?: string
}

export interface Team {
  id: string // team id
  name: string
  shortName: string
  imgUrl: string
  isHome: boolean
  isWon: boolean
  stateId: string
  districtId: string
  schoolId: string
  sportId: string
  score: number
  standings: Nullish<SchoolStandings>
}

export interface Game {
  id: string // game id
  name: string
  eventDate: string
  startTime: string | null
  endTime: string | null
  year: string
  season: string
  isNoHomeTeam: boolean
  isTBD: boolean
  sport: {
    id: string
    name: string
  }
  teams: Team[]
}

export interface GamesResponse {
  games: Game[]
}

export function sortTeams(teams: Team[]) {
  return [...teams].sort((a, b) => Number(a.isHome) - Number(b.isHome))
}
