import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'
import { apiUrl } from 'features/Env'

const baseQuery = fetchBaseQuery({
  baseUrl: `${apiUrl}/api/csz/distribution`,
  prepareHeaders: (headers) => headers,
})

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 1 })

export const rtkApi = createApi({
  baseQuery: baseQueryWithRetry,
  endpoints: () => ({}),
})
